import React, { useRef } from "react";
import {Seo} from "components/seo";
import TitleIntro from "components/sections/titleIntro";
import BaseButton from "components/button/BaseButton";
import Input from "components/input";
import CustomSelect from "components/customSelect"
import { useState, useEffect } from "react";
import Dropzone from "components/dropzone";
import {Link} from "gatsby";

interface Props {
  location : Location
}

export default function Contact({ location } : Props ) {
  const form = useRef(null);
  const optionsListObject = ['Démarrer un projet',"Rejoindre l'équipe"];
  const [selectedObject, setSelectedObject] = useState<string >(optionsListObject[0]);
  const [selectedEmail, setSelectedEmail] = useState<number | string >('');
  const [checkedOption, setCheckedOption] = useState<boolean | null>(false);

  const randomNumberContact = '471553bcc60156ba7ad576b434dbb625'
  const randomNumberJobs = '0b32e11540d7f35b4a6d457085a67e34'

  const handleChangeObject = e => {
    if (e.target) {
      setSelectedObject(optionsListObject[e.target.value])
    } else {
      setSelectedObject(optionsListObject[e])
    }
  };
  useEffect(()=>{
    if(selectedObject == optionsListObject[0]){
      setSelectedEmail(randomNumberContact)
    }
    else {
      setSelectedEmail(randomNumberJobs)
    }
  }, [selectedObject])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("type")) {
      const parameter1: string | null = params.get("type");
      if(parameter1 == 'projet') {
        setSelectedObject(optionsListObject[0])
        setSelectedEmail(randomNumberContact)
      } else {
        setSelectedObject(optionsListObject[1])
        setSelectedEmail(randomNumberJobs)
      }
    }
  }, [location]);

  const url = `https://formsubmit.co/${selectedEmail}`;

  const handleClickPlausible = () => {
    if ("plausible" in window) {
      window.plausible("form-submit");
    }
  }

  return (
    <>
      <Seo title="VingtCinq | Fabrique Digitale - Contactez-nous" description={"Que vous souhaitiez démarrer un nouveau projet ou rejoindre l’équipe"} />
      <TitleIntro className="lg:pb-16" title="Contact" />
      <section className="container lg:px-52">
        <article>
          <form
            ref={form}
            className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 mb-20"
            method="POST"
            action={url}
            encType="multipart/form-data"
          >
            <div className="flex-col hidden lg:flex">
              <span className="form-label">objet</span>
              <CustomSelect buttonClassName={"w-full"} value={selectedObject} options={optionsListObject} onClick={handleChangeObject}/>
            </div>
            <div className="flex lg:hidden flex-col">
              <label htmlFor="object" className="form-label">
                objet
              </label>
              <select
                name="object"
                id="object"
                value={selectedObject}
                className="form-select border-2 border-gray-100 py-1.5 px-2 placeholder:text-gray-500 focus:outline-none focus:border-yellow h-11"
                onChange={handleChangeObject}
              >
                {
                  optionsListObject.map((option:string, index: number) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))
                }
              </select>
            </div>
            <Input
              id="email"
              label="Email"
              type="email"
            />

            <Input
              id="lastname"
              label="Nom"
              type="text"
            />
            <Input
              id="name"
              label="prénom"
              type="text"
            />
            <input
              type="hidden"
              name="_next"
              value={location.origin + "/contact/merci" }
            />
            <input type="text" name="_honey" className="hidden" />
            <input type="hidden" name="_captcha" value="false" />
            {selectedObject == optionsListObject[1] && (
              <Dropzone />
            )}
            <div className="flex flex-col col-span-full">
              <label htmlFor="message" className="form-label">
                message
              </label>
              <textarea
                name="message"
                id="message"
                required
                className="border-2 border-gray-100 py-1.5 px-2 placeholder:text-gray-500 focus:outline-none focus:border-yellow h-28"
              />
              <div className="mt-2">
                <input type="hidden" name="privacy_notice" value="non"/>
                <input
                  type="checkbox"
                  name="privacy_notice"
                  id="privacy_notice"
                  value="oui"
                  onChange={() => {setCheckedOption(!checkedOption)}}
                  className="appearance-none h-4 w-4 border-2 border-gray-100 bg-white checked:bg-yellow checked:border-yellow focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                />
                <label htmlFor="privacy_notice" className='text-b3'>
                  En cochant cette case, j’affirme avoir pris connaissance de la <Link to={"/politique-de-confidentialite"} className="hover:underline"> politique de confidentialité </Link> de La Fabrique VingtCinq
                </label>
              </div>
            </div>
            <BaseButton
              onClick={() => handleClickPlausible()}
              type="submit"
              size="md"
              className="col-span-full disabled:cursor-not-allowed"
              disabled={!checkedOption}>
              Envoyer
            </BaseButton>
          </form>
        </article>
      </section>
    </>
  );
}
