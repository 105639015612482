import {RefObject, useEffect, useState} from "react";

export const capitalizeFirstLetter = (string:string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const moveOthersToEnd = (arr: string[] | undefined[], oldIndex: number, newIndex: number) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const useDetectOutsideClick = (ref: RefObject<HTMLElement>, initialState: boolean) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const pageClickEvent = (event: MouseEvent) => {
      // If the active element exists and is clicked outside of
      if (ref.current !== null && !ref.current.contains(event.target as Node)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive, ref]);

  return [isActive, setIsActive] as const;
};
