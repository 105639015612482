import React, {useEffect, useState, useRef} from "react";
import classNames from "classnames/bind";
import {useDetectOutsideClick} from 'assets/js/utils'


type selectProps = {
  containerClassName?: string
  buttonClassName?: string
  value?: any
  options ?: any
  onClick?: (e: number) => void;
}
const CustomSelect = ({value, options, onClick, buttonClassName, containerClassName} : selectProps) => {
  const listRef = useRef(null);

  const [isOptionsOpen, setIsOptionsOpen] = useDetectOutsideClick(listRef, false);
  const [selectedOption, setSelectedOption] = useState<number | string>(value);

  const toggleOptions = () => setIsOptionsOpen(!isOptionsOpen);

  const handleSelect = (index:number) => {
    onClick(index)
    setSelectedOption(index)
    setIsOptionsOpen(false);
  };


  useEffect(() => {
    if ( typeof value === 'string' ) {
      setSelectedOption(options.indexOf(value));
    } else {
      setSelectedOption(value);
    }
  }, [value]);

  return (
      <div className={ `relative ${containerClassName}`}>
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={classNames ('form-select border-2 border-gray-100 py-1.5 px-2 placeholder:text-gray-500 focus:outline-none focus:border-yellow h-11 text-left relative pr-20', buttonClassName ,{
            "expanded" : isOptionsOpen }
          )}
          onClick={toggleOptions}
        >
          {options[selectedOption]}
        </button>
        <ul
          ref={listRef}
          className={classNames('options', {
            'visible absolute top-12 border-2 border-gray-100 bg-white w-full text-gray-700 z-20' : isOptionsOpen,
            'hidden' : !isOptionsOpen
          })}
          role="listbox"
          tabIndex={-1}
        >
          {
            options.map((option, index) => {
              return (<li
                id={option}
                key={index}
                className="p-2.5 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                role="option"
                aria-selected={selectedOption == index}
                tabIndex={0}
                onClick={() => {
                  handleSelect(index);
                }}
              >
                {option}
              </li>)
            })
          }
        </ul>
      </div>
  );
};

export default CustomSelect;
