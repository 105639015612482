import React, { ChangeEvent, useCallback, useState } from "react";
import PlusIcon from "../assets/svg/plus.svg";
import CloseIcon from "../assets/svg/close.svg";

const Dropzone: React.FC<{}> = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string>('');

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "Ko", "Mo", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const removeFile = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    if (files.length) {
      let newFiles = [...files]
      newFiles.splice(index, 1)
      setFiles(newFiles);
    }
    return false
  };

  const handleFileChange = (event: ChangeEvent) => {
    const input = event.target as HTMLInputElement;
    if (input && input.files?.length) {
      const inputFiles = [];
      const files = input.files;
      const arr = [...files];

      arr.forEach(file => {
        if (file.size > 2097152 )
          setError("File size cannot exceed more than 2MB" );
        else
          return inputFiles.push(file)
      });
      setFiles(inputFiles);
    }
  };

  const listFiles =
    files && files.length ? (
      files.map((file, index) => {
        return (
          <li
            key={file.name}
            className="flex gap-x-3 items-center justify-between px-3 py-1 bg-white rounded-full self-center"
          >
            <div className="inline-flex items-baseline gap-2 truncate">
              {file.name}
              <span className="text-b4 text-gray-600">
                {formatBytes(file.size)}
              </span>
            </div>
            <button
              type="button"
              className="w-3 h-3"
              onClick={(event) => removeFile(event, index)}
            >
              <CloseIcon />
            </button>
          </li>
        );
      })
    ) : (
      <></>
    );

  return (
    <div className="col-span-full">
      <div className="relative">
        <input
          type="file"
          accept="application/pdf"
          id="files"
          name="files"
          className="visually-hidden"
          onChange={(event) => handleFileChange(event)}
          multiple
        />
        <label htmlFor="files" className="flex flex-col text-center p-6 border border-dashed border-1 bg-gray-50 border-gray-200 cursor-pointer">
          <span className="label2 uppercase mb-4 ">Ajouter des fichiers</span>
          <span className="text-gray-700 mb-2">
            CV, lettre de motivation...
          </span>
          {Boolean(files.length) && <ul className="self-center flex flex-col gap-3">{listFiles}</ul>}
          <span className="self-center bg-yellow rounded-full p-3 w-12 h-12 mt-4 group">
            <PlusIcon className="group-hover:animate-fullCircle"/>
          </span>
        </label>
        {error && (<p className="caption3 text-red-500 uppercase mt-1">
          {error}
          </p>)
        }
      </div>
    </div>
  );
};
export default Dropzone;
