import React from "react";
import { useRef, useState } from "react";
import classNames from "classnames/bind";

type inputProps = {
  id: string
  containerClassName?: string
  labelClassName?: string
  inputClassName?: string
  placeholder?: string
  label?: string
  type ?: string
  error ?: boolean
  errorText?: string
}


const Input = ({
                 id,
                 containerClassName = "",
                 labelClassName = "",
                 inputClassName = "",
                 placeholder = "",
                 label = "",
                 type = "text",
                 error = false,
                 errorText = "",
                 ...rest
               }: inputProps ) => {


  const inputRef = useRef<HTMLInputElement>(null);
  const [blur, setBlur] = useState(false)

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      return e.target.value.length > 1 ? setBlur(true) : setBlur(false)
  }

  return (
    <div
      className={`flex flex-col ${containerClassName}`}
      onClick={() => inputRef.current!.focus()}
    >
      <label htmlFor={id} className={`label2 uppercase mb-1 ${labelClassName}`}>
        {label}
      </label>
      <input
        ref={inputRef}
        name={id}
        type={type}
        required
        onBlur={(e)=>onBlur(e)}
        className={ classNames("border-2 py-1.5 px-2 placeholder:text-gray-500 focus:outline-none focus:border-yellow h-11",inputClassName, {
          "border-red-500" : error,
          "border-gray-100" : !error,
          "border-yellow": blur
          })
        }
        id={id}
        placeholder={placeholder}
        {...rest}
      />
      {errorText && (
        <p className="caption3 text-red-500 uppercase mt-1">{errorText}</p>
      )}
    </div>
  );
};

export default Input;
